(function ($) {
    $(window).load(function () {

        autosize($('#message'));


        $("a.mailto").on('click', function () {
            var nom = $(this).attr('data-user');
            mailto(nom);
        });

        var mailto = function (user) {
            var w = '.',
                x = '@',
                y = 'com',
                z = user + x + 'urgencemedia' + w + y;
            window.location.href = 'mailto:' + z;
        };

        var validator_form_contact = $("#form_contact").validate({
            submitHandler: function (form) {
                $("#MessageSent2").addClass("hidden");
                $("#MessageNotSent2").addClass("hidden");
                $("#form_contact .form-control").parent().removeClass("has-success").removeClass("has-error");
                $('#form_contact .submit-button').html('Chargement...').prop("disabled", true).addClass("disabled");

                $.post('/ajax/envoyer', $('#form_contact').serialize(), function (data) {

                    $('#form_contact .submit-button').html('Envoyer <i class="fa fa-check"></i>').prop("disabled", false).removeClass("disabled");
                    if (typeof (data.erreur) !== 'undefined' && data.erreur != '') {
                        var erreur_titre = '';
                        if (typeof (data.champs) !== 'undefined' && !jQuery.isEmptyObject(data.erreur)) {
                            erreur_titre = '<strong>Attention, certains champs ne sont pas remplis correctement :</strong>';
                            validator_form_contact.showErrors(data.champs);
                        } else {
                            erreur_titre = '<strong>Désolé, un problème est survenu</strong>';
                        }
                        $("#MessageNotSent2").html(erreur_titre + '<br>' + data.erreur).removeClass("hidden");
                        $("#MessageSent2").addClass("hidden");

                    } else {
                        if (data.resultat == 'ok') {
                            $("#MessageSent2").removeClass("hidden");
                            $("#MessageNotSent2").addClass("hidden");
                            $("#form_contact .modal-body").hide();
                            $("#form_contact .popin_fermer").show();
                            $("#form_contact .submit-button").hide();
                            //$("#form_contact .submit-button").addClass("btn-success").prop('value', 'Message envoyé'); //.removeClass("btn-default")
                            //$("#form_contact .form-control").prop('value', '').parent().removeClass("has-success").removeClass("has-error");

                            //                            $("#form_contact .form-control").each(function () {
                            //                                $(this).prop('value', '').parent().removeClass("has-success").removeClass("has-error");
                            //                            });
                            //$('#modal_contact').modal('hide');

                        } else {
                            $("#MessageNotSent2").removeClass("hidden");
                            $("#MessageSent2").addClass("hidden");
                        }
                    }

                }, "json").done(function (data, textStatus, jqXHR) {}).fail(function (jqXHR, textStatus, errorThrown) {}).always(function () {});

            },
            focusCleanup: true,
            errorPlacement: function (error, element) {
                error.insertAfter(element);
            },
            onkeyup: false,
            onclick: false,
            rules: {
                "societe": {
                    required: true,
                    minlength: 2
                },
                "nom": {
                    required: true,
                    minlength: 2
                },
                "email": {
                    required: true,
                    email: true
                },
                "telephone": {
                    required: true,
                    digits: true,
                    minlength: 10
                },
                "offre": {
                    required: false
                }
            },
            messages: {
                "societe": {
                    required: "Veuillez remplir le nom de votre société",
                    minlength: "Votre société doit comporter au minimum 2 caractères"
                },
                "nom": {
                    required: "Veuillez remplir votre nom",
                    minlength: "Votre votre doit comporter au minimum 2 caractères"
                },
                "email": {
                    required: "Nous avons besoin de votre email pour vous recontacter",
                    email: "Rentrez une adresse email valide"
                },
                "telephone": {
                    required: "Nous avons besoin de votre téléphone pour vous recontacter",
                    digits: "Votre téléphone ne doit comporter que des chiffres",
                    minlength: jQuery.validator.format("Votre téléphone doit comporter {0} chiffres")
                }
            },
            errorElement: "span",
            highlight: function (element) {
                $(element).parent().removeClass("has-success").addClass("has-error");
                $(element).siblings("label").addClass("hide");
            },
            success: function (element) {
                $(element).parent().removeClass("has-error").addClass("has-success");
                $(element).siblings("label").removeClass("hide");
            }
        });


        $('#modal_contact').on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget),
                type = button.data('type'), // devis ou contact ou offre
                rubrique = button.data('rubrique') ? ' - ' + button.data('rubrique').toUpperCase() : '',
                modal = $(this),
                modal_title = 'CONTACT' + rubrique,
                merci = '<strong>Nous avons reçu votre message</strong>, nous vous contacterons dans les meilleurs délais.',
                title = 'Pouvons-nous vous conseiller ?';

            // Initialisation
            validator_form_contact.resetForm();
            $('#form_contact #sujet').val(type.toUpperCase() + rubrique);
            $('#form_contact #offre').val('');
            $("#form_contact #cible,#form_contact #zone").val(null).trigger("change");
            $("#form_contact .form-control").parent().removeClass("has-success").removeClass("has-error");
            $("#form_contact")[0].reset();
            $('#form_contact .submit-button').show();
            $("#form_contact .modal-body").show();
            $("#form_contact .popin_fermer").hide();
            $("#MessageSent2").addClass("hidden");
            $("#MessageNotSent2").addClass("hidden");

            if (type === 'devis') {
                modal_title = 'DEVIS GRATUIT' + rubrique;
                merci = '<strong>Nous avons reçu votre demande</strong>, nous vous contacterons sous 24H.';
                title = 'Précisez votre demande';
                modal.find('.contact, .offre').hide();
                modal.find('.devis').show();
            } else if (type === 'offre') {
                modal_title = "OFFRE" + rubrique;
                merci = '<strong>Nous avons reçu votre demande</strong>, nous vous contacterons sous 24H.';
                title = "Détail de l'offre";
                $('#offre').val(button.data('titre'));
                modal.find('.contact, .devis').hide();
                modal.find('.offre').show();
            } else {
                modal.find('.devis, .offre').hide();
                modal.find('.contact').show();
            }

            modal.find('.modal-title').text(modal_title);
            modal.find('.title').text(title);
            modal.find('.merci').val(merci);

            $('#cible, #zone').select2({
                allowClear: true
            }).on("select2:unselecting", function (e) {
                $(this).data('state', 'unselected');
            }).on("select2:open", function (e) {
                if ($(this).data('state') === 'unselected') {
                    $(this).removeData('state');
                    var self = $(this);
                    setTimeout(function () {
                        self.select2('close');
                    }, 1);
                }
            }).on("select2:selecting", function (e) {});
        });

        /*
         $('#modal_contact').on('show.bs.modal', function () {
         validator_form_contact.resetForm();
         $("#form_contact #cible,#form_contact #zone").val(null).trigger("change");
         $("#form_contact .form-control").parent().removeClass("has-success").removeClass("has-error");
         $("#form_contact")[0].reset();
         $('#form_contact .submit-button').show();
         $("#form_contact .modal-body").show();
         $("#form_contact .popin_fermer").hide();
         $("#MessageSent2").addClass("hidden");
         $("#MessageNotSent2").addClass("hidden");
         })
         */
    });
})(this.jQuery);
